import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneAlt,
  faEnvelope,
  faMapMarkerAlt,
  faClock,
} from "@fortawesome/free-solid-svg-icons";

const Contact = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-blue-700 text-white text-center py-16">
          <h1 className="text-5xl font-bold mb-4">Contact Us</h1>
          <p className="text-lg">
            Have questions? We're here to help! Reach out to us and we'll get back to you promptly.
          </p>
        </div>
      </div>

      {/* Contact Info Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Get in Touch
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-6xl mx-auto">
          <div className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center">
            <FontAwesomeIcon icon={faPhoneAlt} className="text-blue-500 text-4xl mb-4" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Phone</h3>
            <p className="text-gray-600">+1 (800) 998334578</p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center">
            <FontAwesomeIcon icon={faEnvelope} className="text-blue-500 text-4xl mb-4" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Email</h3>
            <p className="text-gray-600">support@didaravel.it.com</p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center">
            <FontAwesomeIcon icon={faMapMarkerAlt} className="text-blue-500 text-4xl mb-4" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Address</h3>
            <p className="text-gray-600">122 Main St, Suite 254, New York, NY 24554, USA</p>
          </div>
          <div className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center">
            <FontAwesomeIcon icon={faClock} className="text-blue-500 text-4xl mb-4" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">Business Hours</h3>
            <p className="text-gray-600">Mon-Fri: 9:00 AM - 6:00 PM</p>
            <p className="text-gray-600">Sat-Sun: Closed</p>
          </div>
        </div>
      </section>

      {/* Contact Form Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">Send Us a Message</h2>
        <div className="max-w-3xl mx-auto bg-white p-8 border rounded-lg shadow-md">
          <form className="space-y-6">
            <div className="flex flex-col md:flex-row md:space-x-4">
              <div className="flex-1">
                <label htmlFor="name" className="block text-left text-gray-700 font-medium mb-2">
                  Name
                </label>
                <input
                  type="text"
                  id="name"
                  className="w-full p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Name"
                  required
                />
              </div>
              <div className="flex-1 mt-4 md:mt-0">
                <label htmlFor="email" className="block text-left text-gray-700 font-medium mb-2">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  className="w-full p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                  placeholder="Your Email"
                  required
                />
              </div>
            </div>
            <div>
              <label htmlFor="subject" className="block text-left text-gray-700 font-medium mb-2">
                Subject
              </label>
              <input
                type="text"
                id="subject"
                className="w-full p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Subject"
                required
              />
            </div>
            <div>
              <label htmlFor="message" className="block text-left text-gray-700 font-medium mb-2">
                Message
              </label>
              <textarea
                id="message"
                rows="6"
                className="w-full p-3 border rounded-lg shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500"
                placeholder="Your message..."
                required
              ></textarea>
            </div>
            <div>
              <button
                type="submit"
                className="w-full bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700"
              >
                Send Message
              </button>
            </div>
          </form>
        </div>
      </section>

      {/* Map Section */}
      <section className="py-12 bg-blue-50">
        <h2 className="text-3xl font-semibold text-blue-700 text-center mb-6">
          Visit Us
        </h2>
        <div className="max-w-6xl mx-auto">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3151.835434508403!2d144.95373631531634!3d-37.8162792797515!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x6ad65d43f1c45b1b%3A0xf0440c4e46d6c10a!2sTravel%20Agency!5e0!3m2!1sen!2sus!4v1637071702155!5m2!1sen!2sus"
            className="w-full h-96 border-0 rounded-lg shadow-md"
            allowFullScreen=""
            loading="lazy"
          ></iframe>
        </div>
      </section>
    </div>
  );
};

export default Contact;

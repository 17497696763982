import React from "react";
import { Link } from "react-router-dom";

const FooterLink = ({ to, label }) => (
  <Link
    to={to}
    className="block text-gray-600 hover:text-green-700 transition duration-300"
  >
    {label}
  </Link>
);

const GlobalFooter = () => {
  return (
    <footer className="bg-green-100 text-gray-600 py-10">
      <div className="container mx-auto px-6">
        <div className="grid grid-cols-1 md:grid-cols-4 gap-8">
          {/* Информация о компании */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-green-700">Company</h4>
            <FooterLink to="/about-us" label="About Us" />
            <FooterLink to="/contact" label="Contact" />
            <FooterLink to="/privacy-policy" label="Privacy Policy" />
          </div>

          {/* Технологии */}
          <div className="space-y-4">
            <h4 className="text-lg font-semibold text-green-700">Technology</h4>
            <FooterLink to="/solutions" label="Solutions" />
          </div>

          {/* Подписка на новости */}
          <div className="md:col-span-2">
            <h4 className="text-lg font-semibold text-green-700">
              Stay Updated
            </h4>
            <p className="text-sm text-gray-500">
              Join our newsletter for the latest updates and travel tips.
            </p>
            <form className="flex mt-4">
              <input
                type="email"
                placeholder="Enter your email"
                className="w-full p-2 rounded-l bg-white border border-gray-300 focus:outline-none"
              />
              <button className="px-4 py-2 bg-green-700 hover:bg-green-800 text-white font-medium rounded-r transition duration-300">
                Subscribe
              </button>
            </form>
          </div>
        </div>

        <div className="text-center mt-10 border-t border-gray-300 pt-6">
          <p className="text-sm text-gray-500">Designed by didaravel</p>
          <p className="text-sm text-gray-500">
            &copy; {new Date().getFullYear()} didaravel. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  );
};

export default GlobalFooter;

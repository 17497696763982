import { useState, useContext } from "react";
import { Link } from "react-router-dom";
import HamburgerMenu from "components/hamburger-menu/HamburgerMenu";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars } from "@fortawesome/free-solid-svg-icons";
import { AuthContext } from "contexts/AuthContext";
import NavbarItems from "components/navbar-items/NavbarItems";

const GlobalNavbar = () => {
  const [isVisible, setIsVisible] = useState(false);
  const { isAuthenticated } = useContext(AuthContext);

  const onHamburgerMenuToggle = () => setIsVisible(!isVisible);

  return (
    <nav className="flex items-center justify-between px-6 py-4 bg-green-700 text-white shadow-md">
      {/* Логотип */}
      <Link to="/" className="text-2xl font-bold text-white tracking-wide">
        didaravel
      </Link>

      {/* Основные ссылки для больших экранов */}
      <ul className="hidden md:flex space-x-6">
        <NavbarItems isAuthenticated={isAuthenticated} />
      </ul>

      {/* Иконка для мобильного меню */}
      <FontAwesomeIcon
        icon={faBars}
        className="text-2xl cursor-pointer md:hidden"
        onClick={onHamburgerMenuToggle}
      />

      {/* Мобильное меню */}
      <HamburgerMenu
        isVisible={isVisible}
        onHamburgerMenuToggle={onHamburgerMenuToggle}
        isAuthenticated={isAuthenticated}
      />
    </nav>
  );
};

export default GlobalNavbar;

import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGlobe,
  faHotel,
  faPlane,
  faBus,
  faShip,
  faPassport,
} from "@fortawesome/free-solid-svg-icons";

import banner from "assets/hero.jpg";
import aboutUsImage from "assets/aboutUsImage.jpg";
import familyTravel from "assets/familyTravel.jpg";
import businessTrip from "assets/businessTrip.png";
import luxuryExperience from "assets/luxuryExperience.jpg";

const Home = () => {
  const navigate = useNavigate();

  const handleLearnMoreClick = () => {
    navigate("/contact");
  };

  const testimonials = [
    {
      quote:
        "didaravel has revolutionized our travel management. Exceptional service!",
      author: "Anna B., Corporate Traveler",
    },
    {
      quote:
        "Reliable, efficient, and seamless. We highly recommend didaravel.",
      author: "Michael T., Executive Tours",
    },
    {
      quote:
        "A one-stop solution for all our travel needs. Outstanding support!",
      author: "Sophia K., Global Ventures",
    },
  ];

  const services = [
    {
      icon: faGlobe,
      title: "Worldwide Travel Solutions",
      description:
        "Access global partners for flights, hotels, and exclusive deals, all on one platform.",
    },
    {
      icon: faHotel,
      title: "Hotel Booking",
      description:
        "Thousands of hotel options at competitive prices to suit every traveler.",
    },
    {
      icon: faPlane,
      title: "Flight Management",
      description:
        "Book, manage, and track flights with ease, anytime, anywhere.",
    },
  ];

  const newFeatures = [
    {
      image: familyTravel,
      title: "Family Travel Packages",
      description:
        "Create unforgettable experiences for families with tailored tours.",
    },
    {
      image: businessTrip,
      title: "Business Travel Solutions",
      description:
        "Streamlined travel options designed specifically for corporate clients.",
    },
    {
      image: luxuryExperience,
      title: "Luxury Experiences",
      description:
        "Exclusive access to luxury resorts, private jets, and VIP services.",
    },
  ];

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <img
          src={banner}
          alt="Transform Your Travel Business with didaravel"
          className="w-full h-[70vh] object-cover"
        />
        <div className="absolute inset-0 bg-gradient-to-b from-blue-900 to-transparent flex items-center justify-center text-center">
          <div className="max-w-3xl px-6">
            <h1 className="text-5xl font-bold text-white mb-6">
              Power Your Travel Business with didaravel
            </h1>
            <p className="text-lg text-gray-200 mb-8">
              Innovative B2B travel solutions at your fingertips.
            </p>
            <button
              onClick={handleLearnMoreClick}
              className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700"
            >
              Contact Us
            </button>
          </div>
        </div>
      </div>

      {/* About Us Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          About didaravel
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <img
            src={aboutUsImage}
            alt="About didaravel"
            className="w-full md:w-1/2 rounded-lg shadow-md"
          />
          <div className="md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700">
              didaravel provides cutting-edge solutions for B2B travel
              businesses. We empower our partners with tools to manage flights,
              hotels, and tours effortlessly.
            </p>
            <p className="mt-4 text-lg text-gray-700">
              Join us to experience a new level of efficiency and service
              excellence.
            </p>
          </div>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Our Core Services
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {services.map((service, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center"
            >
              <FontAwesomeIcon
                icon={service.icon}
                className="text-blue-500 text-4xl mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900 mb-2">
                {service.title}
              </h3>
              <p className="text-gray-600">{service.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* New Features Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Explore New Features
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {newFeatures.map((feature, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <img
                src={feature.image}
                alt={feature.title}
                className="w-full h-40 object-cover rounded-md mb-4"
              />
              <h3 className="text-xl font-medium text-gray-900">
                {feature.title}
              </h3>
              <p className="text-gray-600">{feature.description}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          What Our Clients Say
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white"
            >
              <p className="text-gray-600 mb-4">"{testimonial.quote}"</p>
              <p className="font-medium text-blue-700">{testimonial.author}</p>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Ready to Elevate Your Business?
        </h2>
        <p className="text-lg text-gray-700 mb-6">
          Join didaravel today and transform your travel operations with
          ease.
        </p>
        <button
          onClick={handleLearnMoreClick}
          className="bg-blue-600 text-white px-6 py-3 rounded-lg shadow-md hover:bg-blue-700"
        >
          Get Started
        </button>
      </section>
    </div>
  );
};

export default Home;

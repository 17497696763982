import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faGlobe,
  faUsers,
  faHotel,
  faShieldAlt,
  faLightbulb,
  faChartLine,
  faCheckCircle,
} from "@fortawesome/free-solid-svg-icons";

import businessTravelImg from "assets/businessTravelImg.webp";
import uniqueSolutionsImg from "assets/uniqueSolutionsImg.png";
import hotelServicesImg from "assets/hotelServicesImg.avif";
import riskManagementImg from "assets/riskManagementImg.jpg";
import innovationImg from "assets/innovationImg.jpg";

const Solutions = () => {
  const pricingPlans = [
    {
      title: "Starter",
      price: "$199/month",
      features: [
        "Access to global travel suppliers",
        "Basic reporting tools",
        "24/7 support",
      ],
    },
    {
      title: "Professional",
      price: "$399/month",
      features: [
        "All Starter features",
        "Advanced analytics & insights",
        "Customized travel solutions",
        "Priority support",
      ],
    },
    {
      title: "Enterprise",
      price: "Contact Us",
      features: [
        "All Professional features",
        "Dedicated account manager",
        "Custom integrations",
        "Exclusive partner benefits",
      ],
    },
  ];

  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-blue-700 text-white text-center py-16">
          <h1 className="text-5xl font-bold mb-4">Our Solutions & Services</h1>
          <p className="text-lg">
            Comprehensive travel solutions tailored to meet the unique needs of
            your business.
          </p>
        </div>
      </div>

      {/* Solutions Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Our Key Solutions
        </h2>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 max-w-6xl mx-auto">
          {/* Business Travel Management */}
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <img
              src={businessTravelImg}
              alt="Business Travel Management"
              className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Business Travel Management
            </h3>
            <p className="text-gray-600">
              Streamline your corporate travel with cost-effective solutions
              that keep your team productive and safe.
            </p>
          </div>

          {/* Unique Travel Solutions */}
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <img
              src={uniqueSolutionsImg}
              alt="Unique Travel Solutions"
              className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Unique Travel Solutions
            </h3>
            <p className="text-gray-600">
              Tailored travel services for industries like energy, media, and
              life sciences.
            </p>
          </div>

          {/* Hotel Services */}
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <img
              src={hotelServicesImg}
              alt="Hotel Services"
              className="w-full h-40 object-cover rounded-md mb-4"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Hotel Services
            </h3>
            <p className="text-gray-600">
              Book the best hotel options with competitive rates and flexible
              policies.
            </p>
          </div>

          {/* Risk Management */}
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <FontAwesomeIcon icon={faGlobe} className="text-blue-500 mr-2" />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Risk Management
            </h3>
            <p className="text-gray-600">
              Proactively manage and mitigate risks to ensure employee safety
              and data security.
            </p>
          </div>

          {/* Innovation & Insights */}
          <div className="p-6 border rounded-lg shadow-md bg-white">
            <FontAwesomeIcon
              icon={innovationImg}
              className="text-blue-500 mr-2"
            />
            <h3 className="text-xl font-medium text-gray-900 mb-2">
              Driving Innovation & Insights
            </h3>
            <p className="text-gray-600">
              Leverage advanced analytics and cutting-edge tools to stay ahead
              of the industry curve.
            </p>
          </div>
        </div>
      </section>

      {/* Pricing Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Pricing Plans
        </h2>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {pricingPlans.map((plan, index) => (
            <div
              key={index}
              className="p-6 border rounded-lg shadow-md bg-white flex flex-col items-center"
            >
              <h3 className="text-xl font-bold text-gray-900 mb-2">
                {plan.title}
              </h3>
              <p className="text-2xl font-medium text-blue-700 mb-4">
                {plan.price}
              </p>
              <ul className="text-gray-600 mb-6">
                {plan.features.map((feature, i) => (
                  <li key={i} className="mb-2">
                    <FontAwesomeIcon
                      icon={faCheckCircle}
                      className="text-blue-500 mr-2"
                    />
                    {feature}
                  </li>
                ))}
              </ul>
              <button className="bg-blue-700 text-white px-4 py-2 rounded-lg hover:bg-blue-800">
                Select Plan
              </button>
            </div>
          ))}
        </div>
      </section>

      {/* Comparison Table */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Compare Plans
        </h2>
        <div className="overflow-x-auto">
          <table className="table-auto w-full max-w-5xl mx-auto border-collapse border border-gray-300">
            <thead>
              <tr className="bg-blue-700 text-white">
                <th className="p-4">Feature</th>
                <th className="p-4">Starter</th>
                <th className="p-4">Professional</th>
                <th className="p-4">Enterprise</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td className="p-4">24/7 Support</td>
                <td className="p-4">✔</td>
                <td className="p-4">✔</td>
                <td className="p-4">✔</td>
              </tr>
              <tr className="bg-gray-100">
                <td className="p-4">Advanced Analytics</td>
                <td className="p-4">✖</td>
                <td className="p-4">✔</td>
                <td className="p-4">✔</td>
              </tr>
              <tr>
                <td className="p-4">Dedicated Account Manager</td>
                <td className="p-4">✖</td>
                <td className="p-4">✖</td>
                <td className="p-4">✔</td>
              </tr>
            </tbody>
          </table>
        </div>
      </section>
    </div>
  );
};

export default Solutions;

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBriefcase,
  faHandshake,
  faUsers,
  faPlaneDeparture,
  faGlobe,
} from "@fortawesome/free-solid-svg-icons";

import aboutImage from "assets/aboutImage.jpg";
import serviceImage from "assets/serviceImage.avif";
import teamImage from "assets/teamImage.jpg";
import globalImage from "assets/globalImage.webp";
import missionImage from "assets/missionImage.jpg";

const About = () => {
  return (
    <div className="font-sans bg-white text-gray-900">
      {/* Hero Section */}
      <div className="relative">
        <div className="bg-blue-700 text-white text-center py-16">
          <h1 className="text-5xl font-bold mb-4">About Us</h1>
          <p className="text-lg">
            Discover who we are, what we stand for, and how we bring value to
            our partners worldwide.
          </p>
        </div>
      </div>

      {/* Company Overview Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Our Company
        </h2>
        <div className="max-w-5xl mx-auto">
          <img
            src={aboutImage}
            alt="About Our Company"
            className="w-full rounded-lg shadow-md mb-6"
          />
          <p className="text-lg text-gray-700 leading-relaxed">
            didaravel is a leading B2B travel platform, offering tailored
            solutions for travel agencies worldwide. We specialize in
            streamlining travel operations through advanced technology,
            connecting businesses with global travel suppliers, and ensuring
            top-tier customer satisfaction. Our mission is to empower our
            partners to provide exceptional travel experiences for their
            clients.
          </p>
        </div>
      </section>

      {/* Services Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          What We Offer
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={serviceImage}
              alt="Our Services"
              className="w-full rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700 leading-relaxed">
              Our platform provides a comprehensive range of services, including
              flight and hotel booking, package tours, and last-minute deals. We
              also offer unique luxury travel experiences, tailored to meet the
              specific needs of our clients. With our intuitive interface,
              managing travel arrangements has never been easier.
            </p>
          </div>
        </div>
      </section>

      {/* Team Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Meet Our Team
        </h2>
        <div className="max-w-5xl mx-auto">
          <img
            src={teamImage}
            alt="Our Team"
            className="w-full rounded-lg shadow-md mb-6"
          />
          <p className="text-lg text-gray-700 leading-relaxed">
            Our dedicated team of industry professionals works tirelessly to
            ensure the success of our partners. From seasoned travel experts to
            innovative tech developers, each team member plays a vital role in
            delivering exceptional service and innovative solutions.
          </p>
        </div>
      </section>

      {/* Global Reach Section */}
      <section className="py-12 bg-blue-50 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Global Network
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={globalImage}
              alt="Global Reach"
              className="w-full rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700 leading-relaxed">
              With a network spanning over 50 countries, didaravel connects
              you to an extensive range of travel suppliers. Our partnerships
              ensure competitive rates, premium services, and unparalleled
              support, enabling you to expand your business on a global scale.
            </p>
          </div>
        </div>
      </section>

      {/* Our Mission Section */}
      <section className="py-12 text-center">
        <h2 className="text-3xl font-semibold text-blue-700 mb-6">
          Our Mission
        </h2>
        <div className="flex flex-col md:flex-row items-center max-w-5xl mx-auto">
          <div className="md:w-1/2">
            <img
              src={missionImage}
              alt="Our Mission"
              className="w-full rounded-lg shadow-md"
            />
          </div>
          <div className="md:w-1/2 md:ml-8 mt-6 md:mt-0 text-left">
            <p className="text-lg text-gray-700 leading-relaxed">
              At didaravel, our mission is to revolutionize the B2B travel
              industry by providing seamless and efficient solutions. We are
              committed to driving innovation, fostering sustainable growth, and
              delivering unparalleled value to our partners.
            </p>
          </div>
        </div>
      </section>

      {/* Call to Action */}
      <section className="py-12 bg-blue-700 text-white text-center">
        <h2 className="text-3xl font-semibold mb-6">Join Our Journey</h2>
        <p className="text-lg mb-6">
          Become a part of the didaravel family and take your business to new
          heights. Let's shape the future of travel together.
        </p>
        <button className="bg-white text-blue-700 px-6 py-3 rounded-lg shadow-md hover:bg-blue-100">
          Get Started
        </button>
      </section>
    </div>
  );
};

export default About;
